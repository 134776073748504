@use 'assets/styles/utils/helper' as h;
@use './theme.tokens' as t;

.mini-tease-local {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  height: 100%;
  width: unset;

  &--live::before {
    animation: live-dot-blink 1.5s ease-in-out infinite;
    background-color: var(--miniplayer__local__dot__color);
    border-radius: 50%;
    content: '';
    display: inline-block;
    height: h.rem(8px);
    margin: var(--miniplayer__local__live__margin);
    width: h.rem(8px);
  }

  &__link {
    width: var(--miniplayer__local__width);
    margin: var(--miniplayer__local__margin);
    display: block;
    flex: 1;
    line-height: 0;
  }

  &__logo {
    display: inline-block;
    color: var(--miniplayer__local__default__logo--fill);

    &:hover {
      opacity: 0.7;
    }
  }
}

.light {
  .mini-tease-local {
    &--live::before {
      background-color: var(--miniplayer__local__dot__color--light);
    }

    &__logo {
      color: var(--miniplayer__local__default__logo--fill--light);

      &:hover {
        opacity: 1;
        color: var(--miniplayer__local__default__logo--fill--light--hover);
      }
    }
  }
}

.transparentLight {
  .mini-tease-local {
    &--live::before {
      background-color: var(--miniplayer__local__dot__color--transparentLight);
    }
  }

  svg.mini-tease-local__logo {
    color: var(--miniplayer__local__default__logo--fill--transparentLight);

    &:hover {
      opacity: 1;
      color: var(--miniplayer__local__default__logo--fill--transparentLight--hover);
    }
  }
}

//
// increased specificity to override header service styling
//
div.mini-player {
  font-family: var(--founders-cond);
  height: 80px;
  line-height: 1;
  overflow: hidden;
  position: relative;
  width: 100%;

  @include h.breakpoint-m {
    width: unset;
    max-width: var(--miniplayer__default__medium-bp--max-width);
  }

  .mini-player__button {
    background: transparent;
    border: none;
    color: inherit;
    cursor: pointer;
    display: flex;
    flex-flow: row nowrap;
    font: inherit;
    margin: 0;
    outline: inherit;
    padding: 0;
    text-align: unset;
    width: 100%;
  }

  .mini-player__tease {
    position: relative;
    flex-shrink: 0;
    width: h.rem(142px);
    height: h.rem(80px);
    display: block;
  }

  .mini-player__content {
    width: h.rem(200px);
    margin: h.rem(15px 15px 0 15px);

    @include h.breakpoint-m {
      margin-right: 0;
    }

    @include h.breakpoint-l {
      width: h.rem(280px);
    }
  }

  .mini-player__logo {
    font-size: 12px;

    svg {
      display: inline-block;
    }

    &-text {
      margin-bottom: var(--miniplayer__default__logo--margin-bottom);
      height: 12px;
      width: 97px;
    }

    // Today

    &-icon {
      color: var(--miniplayer__default__logo-alt--fill);
      margin-bottom: 7px;
      width: 22px;
      height: 10px;
    }

    &-today {
      color: var(--miniplayer__default__logo--fill);
    }

    &-allday {
      color: var(--miniplayer__default__logo-alt--fill);
    }
  }

  .mini-player__headline {
    // @include line-clamp(2, var(--miniplayer__default__headline--font-size), #000);
    color: var(--miniplayer__default__headline--color);
    font-size: var(--miniplayer__default__headline--font-size);
    margin: -4px 0 0; // account for font spacing irregularity
    overflow: hidden;
  }

  .mini-player__live-flag::before {
    animation: live-dot-blink 1.5s ease-in-out infinite;
    background-color: #fa3a3a;
    border-radius: 50%;
    content: '';
    display: inline-block;
    height: 8px;
    margin-bottom: 2px;
    margin-right: 5px;
    width: 8px;
  }

  .mini-player__typeicon.typeicon {
    background: var(--miniplayer__default__action--background-color);
    color: var(--miniplayer__default__action--color);

    &::before {
      font-size: h.rem(8px);
    }
  }

  .mini-player__sign-in {
    height: h.rem(16px);
    position: absolute;
    right: h.rem(15px);
    top: h.rem(10px);
    width: h.rem(62px);

    @include h.breakpoint-m {
      right: 0;
    }
  }

  .mini-player__sign-in-button {
    align-items: flex-end;
    background: transparent;
    border: none;
    color: var(--miniplayer__default__action--background-color);
    display: flex;
    flex-flow: row nowrap;
    font-size: 0;
    height: 100%;
    outline: none;
    width: 100%;

    &:focus {
      outline: 0;
    }

    &:hover {
      opacity: var(--opacity-70);
    }

    &:active {
      opacity: var(--opacity-50);
    }

    & .icon-lock {
      font-size: var(--miniplayer__default__signin--font-size);
    }
  }

  .mini-player__sign-in-button-text {
    font-size: var(--miniplayer__default__signin--font-size);
    margin: h.rem(0 0 -2px 3px);
    text-transform: uppercase;
  }

  // required to prevent tease image from jumping on page load
  img {
    display: block;
  }

  //
  // Light 'theme' overrides
  //
  &.mini-player--light {
    background-color: var(--miniplayer__light--background-color);
    margin-bottom: var(--miniplayer__light--margin-bottom);

    .mini-player__content {
      width: auto;
    }

    .mini-player__logo {
      fill: var(--miniplayer__light__logo--fill);
      margin-bottom: var(--miniplayer__light__logo--margin-bottom);
    }

    .mini-player__headline {
      color: var(--miniplayer__light__headline--color);
    }
  }

  // Avoid specificity error
  .mini-player__button:hover .mini-player__content {
    opacity: var(--opacity-70);
  }

  .mini-player__button:active .mini-player__content {
    opacity: var(--opacity-50);
  }
}
