@use 'assets/styles/utils/helper' as h;

:global(.skip-link) {
  --skip-link-overlay-default-background-color: var(--default-color);
  --skip-link-overlay-default-border-color: var(--default-color);
  --skip-link-button-default-background-color: var(--default-neutral);
  --skip-link-button-default-text-color: var(--default-text-color);
  --skip-link-button-font-family: var(--founders-cond);
  --skip-link-overlay-height: #{h.rem(60px)};
  position: absolute;
  top: -100vh;
  left: -100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: var(--skip-link-overlay-default-background-color);
  border: 2px solid var(--skip-link-overlay-default-border-color);
  box-sizing: border-box;
  height: var(--skip-link-overlay-height);

  &:focus-within {
    z-index: var(--skip-link-overlay-z-index, 0);
    inset: 0;
  }

  :global(body.msnbc) & {
    --skip-link-overlay-default-background-color: var(--blue-10);
    --skip-link-overlay-default-border-color: var(--blue-20);
    --skip-link-button-default-background-color: var(--blue-40);
    --skip-link-button-default-text-color: var(--white);
  }

  :global(body.news) & {
    --skip-link-overlay-default-background-color: var(--blue-20);
    --skip-link-overlay-default-border-color: var(--blue-30);
    --skip-link-button-default-background-color: var(--blue-40);
    --skip-link-button-default-text-color: var(--white);
  }

  :global(body.today) & {
    --skip-link-overlay-default-background-color: var(--orange-10);
    --skip-link-overlay-default-border-color: var(--orange-20);
    --skip-link-button-default-background-color: var(--orange-30);
    --skip-link-button-default-text-color: var(--white);
    --skip-link-button-font-family: var(--secondary-font);
    --skip-link-overlay-height: #{h.rem(94px)};
    --skip-link-button-icon-margin-bottom: 3px;

    @include h.breakpoint-m {
      --skip-link-overlay-height: #{h.rem(60px)};
    }
  }

  :global(body.telemundo) & {
    --skip-link-overlay-default-background-color: var(--neutral-10);
    --skip-link-overlay-default-border-color: var(--blue-10);
    --skip-link-button-default-background-color: var(--red-50);
    --skip-link-button-default-text-color: var(--white);
    --skip-link-overlay-height: #{h.rem(60px)};

    @include h.breakpoint-m {
      --skip-link-overlay-height: #{h.rem(128px)};
    }
  }

  :global(body.noticias) & {
    --skip-link-overlay-default-background-color: var(--neutral-10);
    --skip-link-overlay-default-border-color: var(--blue-10);
    --skip-link-button-default-background-color: var(--red-50);
    --skip-link-button-default-text-color: var(--white);
    --skip-link-overlay-height: #{h.rem(60px)};

    @include h.breakpoint-m {
      --skip-link-overlay-height: #{h.rem(128px)};
    }
  }

  :global(body.think) & {
    --skip-link-overlay-default-background-color: var(--red-10);
    --skip-link-overlay-default-border-color: var(--red-60);
    --skip-link-button-default-background-color: var(--red-70);
    --skip-link-overlay-height: #{h.rem(60px)};
    --skip-link-button-default-text-color: var(--white);

    @include h.breakpoint-m {
      --skip-link-overlay-height: #{h.rem(128px)};
    }
  }

  :global(body.better) & {
    --skip-link-overlay-default-background-color: var(--teal-60);
    --skip-link-overlay-default-border-color: var(--teal-70);
    --skip-link-overlay-height: #{h.rem(60px)};

    @include h.breakpoint-m {
      --skip-link-overlay-height: #{h.rem(128px)};
    }
  }
}

.skip-link__button {
  background-color: var(--skip-link-button-default-background-color);
  color: var(--skip-link-button-default-text-color);
  height: 40px;
  outline: none;
  border: none;
  font-family: var(--skip-link-button-font-family);
  font-size: h.rem(16px);
  font-style: normal;
  font-weight: 600;
  line-height: h.rem(16px);
  letter-spacing: 0;
  text-align: center;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;

  &:focus {
    outline: 3px solid #0db14b;
    outline-offset: 3px;
  }

  &--fixed {
    width: 200px;
  }

  &--auto {
    padding: 0 20px;
  }
}

.skip-link__button-icon {
  margin-bottom: var(--skip-link-button-icon-margin-bottom, 0);
  margin-left: 4px;

  & > path {
    fill: var(--skip-link-button-default-text-color);
  }
}
